body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none;
}
a:visited {
  color: initial;
}
.social {
  display: flex;
  justify-content: space-between;
  width: 30%;
}
@font-face {
  font-family: 'icomoon';
  src:  url('icons/icomoon.eot?enrz5t');
  src:  url('icons/icomoon.eot?enrz5t#iefix') format('embedded-opentype'),
    url('icons/icomoon.ttf?enrz5t') format('truetype'),
    url('icons/icomoon.woff?enrz5t') format('woff'),
    url('icons/icomoon.svg?enrz5t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-github:before {
  content: "\e905";
}
.icon-linkedin:before {
  content: "\e902";
  color: #0077b5;
}
.icon-youtube:before {
  content: "\e903";
  color: #f00;
}
.icon-medium:before {
  color: black;
  content: "\e904";
}
.icon-facebook:before {
  content: "\e901";
  color: #3b5998;
}
.icon-twitter:before {
  content: "\e900";
  color: #1da1f2;
}
