body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none;
}
a:visited {
  color: #000;
  color: initial;
}
.social {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 30%;
}
@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.29cc859c.eot);
  src:  url(/static/media/icomoon.29cc859c.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.a86cb30d.ttf) format('truetype'),
    url(/static/media/icomoon.b964d31a.woff) format('woff'),
    url(/static/media/icomoon.08b5932c.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-github:before {
  content: "\E905";
}
.icon-linkedin:before {
  content: "\E902";
  color: #0077b5;
}
.icon-youtube:before {
  content: "\E903";
  color: #f00;
}
.icon-medium:before {
  color: black;
  content: "\E904";
}
.icon-facebook:before {
  content: "\E901";
  color: #3b5998;
}
.icon-twitter:before {
  content: "\E900";
  color: #1da1f2;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-header {
  background-color: #f8f8f8;
  color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
}




